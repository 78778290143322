import { useTheme } from '@material-ui/core/styles';
import { Grid, IconButton, List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useEffect, useState } from 'react'
import { RSVP } from './constants';
import api from './api';
import { useParams } from 'react-router-dom';
import { EventContext } from './App';
import { TitleBanner } from './components/titleBanner';
import { DeleteConfirmationDialog } from './components/deleteConfirmDialog';

export const ViewGuests = () => {
  const { eventId, adminId } = useParams()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [activeAttendee, setActiveAttendee] = useState()
  const { event, setEvent } = useContext(EventContext)
  const handlePopupOpen = (attendee) => {
    setActiveAttendee(attendee)
    setDeleteDialogOpen(true);
  };
  const theme = useTheme();
  useEffect(() => {
    if (!event || event.id !== eventId) {
      api.getEvent(eventId).then(setEvent)
    }
  }, [eventId]);

  if (!event) {
    return <TitleBanner event={{ title: 'Just a sec...' }} />
  }
  const guests = Object.values(event.attendees);

  const getAttendeeListItem = (guest, adminId) => { //Todo not nicest handling of adminId
    if (adminId) {
      return (
        <ListItem key={`${guest.attendeeIdHash}`}
          secondaryAction={<IconButton edge="end" label="delete"
            onClick={() => handlePopupOpen(guest)}>
            <CloseIcon icon-style={{ height: '25%', width: '25%' }} />
          </IconButton>}>
          <ListItemText primary={`${guest.name}`} />
        </ListItem>
      )
    } else {
      return (
        <ListItem key={`${guest.attendeeIdHash}`}>
          <ListItemText primary={`${guest.name}`} />
        </ListItem>
      )
    }
  }

  return (
    <Grid container spacing={2} textAlign="left"
      justifyContent="space-around" flexDirection="column"
      sx={{
        border: 1, padding: 1, borderRadius: 1, borderColor: 'grey.200',
        minWidth: 200,
      }}
    >
      {[RSVP.ATTENDED, RSVP.DECLINED, RSVP.UNDECIDED].map((rsvp_header, i) => (
        <Grid item sm={12} key={i}>
          <List>
            <ListSubheader sx={{ backgroundColor: theme.palette.background.default }}>
              {`${rsvp_header}`}
            </ListSubheader>
            {guests.map((guest) => {
              if (guest.rsvp === rsvp_header) {
                return getAttendeeListItem(guest, adminId)
              }
              return null;
            })}
          </List >
        </Grid>
      ))}
      <DeleteConfirmationDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} adminId={adminId} eventId={eventId} attendee={activeAttendee} />
    </Grid >
  );
}